import React, { FC } from "react";
import { Controller, UseFormGetValues, useFieldArray } from "react-hook-form";
import { formatColumn } from "../../../Helper/formatColumn";
import useAlert from "../../../hooks/useAlert";
import { IAutoNumberAttibute } from "../../../IRequestModel/IAutoNumberFormat";
import { IMemoDetailModel } from "../../../IRequestModel/IMemoDetailModel";
import { AttachmentControlComponent } from "../../AntdControlComponent/AttachmentUploadControlComponent/AttachmentComponent";
import { AutoNumber } from "../../AntdControlComponent/AutoNumberFix/AutoNumberFix";
import { DatePickerControlComponent } from "../../AntdControlComponent/DatePickerControlComponent/DatePickerControlComponent";
import { InputControlComponent } from "../../AntdControlComponent/InputControlComponent/InputControlComponent";
import { InputNumberControlComponent } from "../../AntdControlComponent/InputNumberControlComponent/InputNumberControlComponent";
import { RadioControlComponent } from "../../AntdControlComponent/RadioControlComponent/RadioControlComponent";
import { SelectDropdownControlComponent } from "../../AntdControlComponent/SelectDropdownContronComponent/SelectDropdownContronComponent";
import TableComponent from "../../AntdControlComponent/TableComponent/TableComponent";
import { InputTextAreaControlComponent } from "../../AntdControlComponent/TextAreaControlComponent/InputTextAreaControlComponent";
import ButtonComponent from "../../ControlComponents/ButtonComponent/ButtonComponent";
import ComponentLabel from "../../ControlComponents/ComponentLabel";
import { EditorComponent } from "../../ControlComponents/EditorComponent/EditorComponent";
import { EmptyComponent } from "../../ControlComponents/EmptyComponent/EmptyComponent";
import ImageComponent from "../../ControlComponents/ImageComponent/ImageComponent";
import MainCheckboxComponents from "../../ControlComponents/MainCheckboxComponents/MainCheckboxComponents";
import MainTextComponents from "../../ControlComponents/MainTextComponents/MainTextComponents";

import $ from "jquery";
import ButtonSpecialComponent from "../../ControlComponents/ButtonComponent/ButtonSpecialComponent";
import { ILogicRequestBody } from "../../../IRequestModel/ILogicModel";

type Props = {
  nestIndex: number;
  control: any;
  register: any;
  documentNo: any;
  controlRef: any;
  onControlChange: (controlTemplate: any, controlValue: any) => any;
  controlUpdate: any;
  calCulateCorecontrol: any;
  isControlLoading: any;
  canEditDoc: boolean;
  checkActionPage: string;
  tableSummaries?: any[];
  updateTableSummaries: (tableTemp: any, value: any) => void;
  buttonType: string;
  autoNumFormat: IAutoNumberAttibute;
  onCheckInterface: any;
  onSubmit: any;
  memoDetail: IMemoDetailModel;
  checkInterFace: boolean;
  returnMessage: string;
  listReturn: any;
  interfaceButton: any;
  actionAlert?: any;
  setButtonType: (data: any) => void;
  edFont?: any;
  defaultFont?: any;
  allLogicInTable: ILogicRequestBody[];
  getValues: UseFormGetValues<{
    items: any;
  }>;
};

const Controls: FC<Props> = ({
  control,
  nestIndex,
  register,
  documentNo,
  controlRef,
  onControlChange,
  controlUpdate,
  autoNumFormat,
  isControlLoading,
  calCulateCorecontrol,
  allLogicInTable,
  memoDetail,
  onCheckInterface,
  onSubmit,
  canEditDoc,
  checkActionPage,
  tableSummaries,
  updateTableSummaries,
  buttonType,
  setButtonType,
  checkInterFace,
  returnMessage,
  listReturn,
  getValues,
  interfaceButton,
  actionAlert,
  edFont,
  defaultFont,
}) => {
  const { toggleAlert } = useAlert();
  const { fields, remove, append, update } = useFieldArray({
    control,
    name: `items[${nestIndex}].layout`,
  });

  return (
    <>
      {fields.map((layout: any, layoutIdx: number) => {
        const layoutLength = fields.length;
        const grid_size = 12 / layoutLength;
        let _colText = 0;
        let _colAction = 0;
        if (layoutLength == 1) {
          _colText =
            layout.template.type === "tb" || layout.template.type === "ed"
              ? 11
              : 2;
          _colAction = layout.template.type === "ed" ? 12 : 10;
        } else if (layoutLength == 2) {
          _colText = 2;
          _colAction = 4;
        }
        let _isCanEdit = canEditDoc;

        if (layout.template.type === "l" && layout.isShow !== false) {
          return (
            <MainTextComponents
              key={layout.id}
              rowIdx={nestIndex}
              colIdx={layoutIdx}
              // onChangeEditForm={onChangeEditForm}
              template={layout.template}
              data={layout.data}
            />
          );
        } else if (layout.template.type === "ed" && layout.isShow !== false) {
          return (
            // <Col md={grid_size} xs={12}>
            <>
              <ComponentLabel
                colText={_colText}
                rowIdx={nestIndex}
                colIdx={layoutIdx}
                template={layout.template}
              />
              <EditorComponent
                key={layout.id}
                buttonType={buttonType}
                rowIdx={nestIndex}
                colIdx={layoutIdx}
                canEditDoc={
                  !layout.isReadOnly && (layout.isEditable || _isCanEdit)
                }
                colText={_colText}
                colAction={_colAction}
                // onChangeEditForm={onChangeEditForm}
                template={layout.template}
                data={layout.data}
                name={`items[${nestIndex}].layout[${layoutIdx}].data.value`}
                control={control}
                edFont={edFont}
                defaultFont={defaultFont}
                // errorValid={error_corecontroll}
                // statusMemoDetail={props.statusMemoDetail}
              />
            </>
            // </Col>
          );
        } else if (layout.template.type === "c" && layout.isShow !== false) {
          return (
            <InputNumberControlComponent
              {...{ checkActionPage, buttonType }}
              name={`items[${nestIndex}].layout[${layoutIdx}].data.value`}
              control={control}
              //  defaultValue={value}
              key={layout.id} // important to include key with field's id
              rowIdx={nestIndex}
              // key={layoutIdx}
              colIdx={layoutIdx}
              // onChangeEditForm={onChange}
              template={layout.template}
              data={layout.data}
              colText={_colText}
              colAction={_colAction}
              onControlChange={onControlChange}
              controlUpdate={controlUpdate}
              canEditDoc={
                !layout.isReadOnly && (layout.isEditable || _isCanEdit)
              }
            />
          );
        } else if (layout.template.type === "ta" && layout.isShow !== false) {
          return (
            <InputTextAreaControlComponent
              {...{ checkActionPage, buttonType }}
              name={`items[${nestIndex}].layout[${layoutIdx}].data.value`}
              key={layout.id} // important to include key with field's id
              rowIdx={nestIndex}
              // key={layoutIdx}
              colIdx={layoutIdx}
              // onChangeEditForm={onChange}
              template={layout.template}
              data={layout.data}
              colText={_colText}
              colAction={_colAction}
              control={control}
              // errorValid={error_corecontroll}
              // statusMemoDetail={props.statusMemoDetail}
              canEditDoc={
                !layout.isReadOnly && (layout.isEditable || _isCanEdit)
              }
            />
          );
          // }
        } else if (layout.template.type === "dd" && layout.isShow !== false) {
          return (
            <SelectDropdownControlComponent
              {...{ checkActionPage, buttonType, allLogicInTable }}
              key={layout.id} // important to include key with field's id
              rowIdx={nestIndex}
              colIdx={layoutIdx}
              template={layout.template}
              data={layout.data}
              colText={_colText}
              colAction={_colAction}
              control={control}
              name={`items[${nestIndex}].layout[${layoutIdx}]`}
              onControlChange={onControlChange}
              controlUpdate={controlUpdate}
              getValues={getValues}
              // errorValid={error_corecontroll}
              // statusMemoDetail={props.statusMemoDetail}
              canEditDoc={
                !layout.isReadOnly && (layout.isEditable || _isCanEdit)
              }
              actionAlert={actionAlert}
            />
          );
        } else if (layout.template.type === "cb" && layout.isShow !== false) {
          return (
            <MainCheckboxComponents
              {...{ checkActionPage, buttonType }}
              key={layout.id} // important to include key with field's id
              rowIdx={nestIndex}
              // key={layoutIdx}
              colIdx={layoutIdx}
              // onChangeEditForm={onChangeEditForm}
              template={layout.template}
              data={layout.data}
              colText={_colText}
              colAction={_colAction}
              control={control}
              onControlChange={onControlChange}
              name={`items[${nestIndex}].layout[${layoutIdx}].data`}
              // errorValid={error_corecontroll}
              // statusMemoDetail={props.statusMemoDetail}
              canEditDoc={
                !layout.isReadOnly && (layout.isEditable || _isCanEdit)
              }
            />
          );
        } else if (layout.template.type === "at" && layout.isShow !== false) {
          return (
            <AttachmentControlComponent
              {...{ checkActionPage, buttonType }}
              key={layout.id} // important to include key with field's id
              rowIdx={nestIndex}
              // key={layoutIdx}
              colIdx={layoutIdx}
              // onChangeEditForm={onChangeEditForm}
              template={layout.template}
              data={layout.data}
              colText={_colText}
              colAction={_colAction}
              control={control}
              name={`items[${nestIndex}].layout[${layoutIdx}].data.value`}
              canEditDoc={
                !layout.isReadOnly && (layout.isEditable || _isCanEdit)
              }
            />
          );
        } else if (layout.template.type === "t" && layout.isShow !== false) {
          return (
            <InputControlComponent
              {...{ checkActionPage, buttonType, onControlChange }}
              key={layout.id} // important to include key with field's id
              rowIdx={nestIndex}
              colIdx={layoutIdx}
              template={layout.template}
              data={layout.data}
              colText={_colText}
              colAction={_colAction}
              name={`items[${nestIndex}].layout[${layoutIdx}].data.value`}
              control={control}
              canEditDoc={
                !layout.isReadOnly && (layout.isEditable || _isCanEdit)
              }
            />
          );
        } else if (layout.template.type === "r" && layout.isShow !== false) {
          return (
            <RadioControlComponent
              {...{ checkActionPage, buttonType }}
              key={layout.id} // important to include key with field's id
              rowIdx={nestIndex}
              // key={layoutIdx}
              colIdx={layoutIdx}
              // onChangeEditForm={onChangeEditForm}
              template={layout.template}
              data={layout.data}
              colText={_colText}
              colAction={_colAction}
              onControlChange={onControlChange}
              name={`items[${nestIndex}].layout[${layoutIdx}].data.value`}
              control={control}
              canEditDoc={
                !layout.isReadOnly && (layout.isEditable || _isCanEdit)
              }
            />
          );
        } else if (layout.template.type === "tb" && layout.isShow !== false) {
          // check all column isShow == false
          // if isHideAllColumn == true then hide all table and label but save data to api on submit
          const columns = layout.template.attribute.column as Array<{
            label: string;
            isShow: boolean | undefined;
          }>;

          const isHideAllColumn = !columns
            .map((o) => (o.isShow == undefined ? true : o.isShow))
            .reduce((pre, cur) => pre || cur);

          return (
            <>
              {/* {!isHideAllColumn && (
                <ComponentLabel
                  colText={_colText}
                  rowIdx={nestIndex}
                  colIdx={layoutIdx}
                  template={layout.template}
                />
              )} */}
              <div hidden={isHideAllColumn}>
                <Controller
                  name={`items[${nestIndex}].layout[${layoutIdx}]`}
                  control={control}
                  defaultValue={layout.data.row}
                  rules={{
                    value: layout.data.row,
                    required: layout.isReadOnly
                      ? false
                      : buttonType !== "draft" &&
                        buttonType !== "reject" &&
                        buttonType !== "cancel" &&
                        buttonType !== "recall" &&
                        buttonType !== ""
                      ? false
                      : true,
                    validate: (value: any) => {
                      try {
                        if (layout.isReadOnly) return true;

                        if (
                          !["submit", "approve", "request comment"].includes(
                            buttonType
                          )
                        ) {
                          return true;
                        }

                        if (
                          buttonType !== "draft" &&
                          buttonType !== "reject" &&
                          buttonType !== "cancel" &&
                          buttonType !== "recall" &&
                          buttonType !== "assign" &&
                          buttonType !== ""
                        ) {
                          let pass: boolean = true;
                          let rows: any = value?.row || value?.data?.row;
                          let errorCols: any[] = [];
                          let checkedRequire = false;
                          layout.template.attribute.column.forEach(
                            (col: any, colIdx: number) => {
                              if (
                                col.control.template.attribute.require === "Y"
                              ) {
                                checkedRequire = true;
                                if (rows) {
                                  rows.forEach((row: any, idx: number) => {
                                    if (
                                      !row[colIdx].value ||
                                      row[colIdx].value === "" ||
                                      row[colIdx].value === "--select--" ||
                                      row[colIdx].value ===
                                        "-- Please Select --" ||
                                      (col.control.template.type === "c" &&
                                        isNaN(Number(row[colIdx].value)))
                                    ) {
                                      errorCols.push(
                                        <li>
                                          {`${
                                            col.control.template.label
                                          } in row ${idx + 1}`}
                                        </li>
                                      );
                                      pass = false;
                                    }
                                  });
                                } else {
                                  pass = false;
                                }
                              }
                            }
                          );

                          if (checkedRequire) {
                            if (rows?.length === 0 || rows === null)
                              pass = false;
                          }
                          if (!pass) {
                            toggleAlert({
                              key: layout.template.label,
                              type: "error",
                              message: "Require field error",
                              description: (
                                <div>
                                  <span>
                                    Please fill all Require field in table{" "}
                                    {layout.template.label}
                                  </span>
                                  <br />
                                  <span> at These columns</span>
                                  <ul>{errorCols}</ul>
                                </div>
                              ),
                              duration: 6,
                            });
                          }
                          return pass;
                        } else {
                          return true;
                        }
                      } catch (error) {
                        console.error("table=>validate=>error", error);
                      }
                    },
                  }}
                  render={({
                    field: { onChange, onBlur, value, name, ref },
                    formState: { errors, isSubmitted },
                  }) => {
                    const { column, newData } = formatColumn(
                      layout.template.attribute.column,
                      value.data
                    );
                    /**
                     * เมื่อมีการ render ui ใหม่ทุกครั้ง script ข้างล่างนี้จะทำการ find input และ textarea ทั้งหมดใน document
                     * จากนั้นจะทำการเซ็ท tabindex เพื่อให้สอดคล้องกับการใช้งานที่กด tab แล้ว input จะ focus
                     */
                    setTimeout(() => {
                      $("input, textarea").each((i, el) => {
                        document
                          .getElementById(el.id)
                          ?.setAttribute("tabindex", (i + 1).toString());
                      });
                    }, 100);

                    return (
                      <div className={`set-layout-required`}>
                        <TableComponent
                          {...{
                            checkActionPage,
                            buttonType,
                            allLogicInTable,
                            getValues,
                          }}
                          checkInterFace={checkInterFace}
                          colText={_colText}
                          rowIdx={nestIndex}
                          colIdx={layoutIdx}
                          setButtonType={setButtonType}
                          canEditDoc={
                            !layout.isReadOnly &&
                            (layout.isEditable || _isCanEdit)
                          }
                          onControlChange={onControlChange}
                          _columns={column.filter(
                            (e: any) => e.isShow || e.isShow === undefined
                          )}
                          _data={newData}
                          memoDetail={memoDetail}
                          // ดักเคส
                          onChange={onChange}
                          layout={layout}
                          isControlLoading={isControlLoading}
                          rowTemplate={nestIndex}
                          colTemplate={layoutIdx}
                          onCheckInterface={onCheckInterface}
                          interfaceButton={interfaceButton}
                          onSubmit={onSubmit}
                          isError={
                            isSubmitted &&
                            errors?.items &&
                            errors?.items[nestIndex] &&
                            errors?.items[nestIndex].layout[layoutIdx] &&
                            errors?.items[nestIndex].layout[layoutIdx]?.data
                              ?.type === "validate"
                          }
                          tableSummary={
                            tableSummaries?.find(
                              (e: any) =>
                                e.tableTemp.label === layout.template.label
                            )?.AllCol
                          }
                          updateTableSummaries={updateTableSummaries}
                          actionAlert={actionAlert}
                        />
                        {isSubmitted &&
                          errors?.items &&
                          errors?.items[nestIndex] &&
                          errors?.items[nestIndex].layout[layoutIdx] &&
                          errors?.items[nestIndex].layout[layoutIdx]?.data
                            ?.type === "validate" && (
                            <small id="Name-help" className="p-error p-d-block">
                              {layout.template.label} is required.
                            </small>
                          )}
                        <div></div>
                      </div>
                    );
                  }}
                />
              </div>
            </>
          );
        } else if (layout.template.type === "d" && layout.isShow !== false) {
          return (
            <DatePickerControlComponent
              {...{ checkActionPage, buttonType, onControlChange }}
              key={layout.id} // important to include key with field's id
              rowIdx={nestIndex}
              colIdx={layoutIdx}
              // onChangeEditForm={onChangeEditForm}
              template={layout.template}
              data={layout.data}
              colText={_colText}
              colAction={_colAction}
              name={`items[${nestIndex}].layout[${layoutIdx}].data.value`}
              control={control}
              // errorValid={error_corecontroll}
              // statusMemoDetail={props.statusMemoDetail}
              canEditDoc={
                !layout.isReadOnly && (layout.isEditable || _isCanEdit)
              }
              onControlChange={onControlChange}
              actionAlert={actionAlert}
            />
          );
        } else if (layout.template.type === "bt" && layout.isShow !== false) {
          //incomplete

          return (
            <ButtonComponent
              {...{ checkActionPage }}
              key={layout.id} // important to include key with field's id
              rowIdx={nestIndex}
              colIdx={layoutIdx}
              // onChangeEditForm={onChangeEditForm}
              template={layout.template}
              data={layout.data}
              colText={_colText}
              colAction={_colAction}
              name={`items[${nestIndex}].layout[${layoutIdx}].data.value`}
              control={control}
              documentNo={documentNo}
              // errorValid={error_corecontroll}
              // statusMemoDetail={props.statusMemoDetail}
              canEditDoc={
                !layout.isReadOnly && (layout.isEditable || _isCanEdit)
              }
            />
          );
        } else if (layout.template.type === "an" && layout.isShow !== false) {
          return (
            <AutoNumber
              {...{ canEditDoc, checkActionPage, buttonType, onControlChange }}
              key={layout.id} // important to include key with field's id
              rowIdx={nestIndex}
              colIdx={layoutIdx}
              // onChangeEditForm={onChangeEditForm}
              template={layout.template}
              data={layout.data}
              colText={_colText}
              colAction={_colAction}
              control={control}
              name={`items[${nestIndex}].layout[${layoutIdx}].data.value`}
              // errorValid={error_corecontroll}
              // statusMemoDetail={props.statusMemoDetail}
            />
          );
        } else if (layout.template.type === "rvs" && layout.isShow !== false) {
          // incomplete
          // setRvsPosition({ rowIdx: i, colIdx: idx });
          return (
            <InputControlComponent
              {...{ checkActionPage, buttonType, onControlChange }}
              key={layout.id} // important to include key with field's id
              rowIdx={nestIndex}
              colIdx={layoutIdx}
              // onChangeEditForm={onChangeEditForm}
              template={layout.template}
              data={layout.data}
              // errorValid={error_corecontroll}
              colText={_colText}
              colAction={_colAction}
              name={`items[${nestIndex}].layout[${layoutIdx}].data.value`}
              control={control}
              canEditDoc={
                !layout.isReadOnly && (layout.isEditable || _isCanEdit)
              }
            />
          );
        } else if (layout.template.type === "im" && layout.isShow !== false) {
          return (
            <ImageComponent
              key={layout.id} // important to include key with field's id
              rowIdx={nestIndex}
              colIdx={layoutIdx}
              buttonType={buttonType}
              canEditDoc={
                !layout.isReadOnly && (layout.isEditable || _isCanEdit)
              }
              // onChangeEditForm={onChangeEditForm}
              template={layout.template}
              data={layout.data}
              colText={_colText}
              colAction={_colAction}
              name={`items[${nestIndex}].layout[${layoutIdx}].data.value`}
              control={control}
              // errorValid={error_corecontroll}
            />
          );
        } else if (
          layout.template.type === "em" ||
          Object.keys(layout.template).length === 0
        ) {
          return (
            <EmptyComponent
              key={layout.id} // important to include key with field's id
              colText={_colText}
              colAction={_colAction}
            />
          );
        } else if (layout.template.type === "spc" && layout.isShow !== false) {
          return (
            <ButtonSpecialComponent
              {...{ checkActionPage }}
              key={layout.id}
              rowIdx={nestIndex}
              colIdx={layoutIdx}
              template={layout.template}
              data={layout.data}
              colText={_colText}
              colAction={_colAction}
              name={`items[${nestIndex}].layout[${layoutIdx}].data.value`}
              control={control}
              documentNo={documentNo}
              canEditDoc={_isCanEdit}
              onCheckInterface={onCheckInterface}
            />
          );
        }
      })}
    </>
  );
};

export default React.memo(Controls);
